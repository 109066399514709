import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import OurTheme, { theme } from '../components/OurTheme'
import PageWrapper from '../components/PageWrapper'
import MinificationArea from '../components/MinificationArea'
import { useDetectSupportedBrowser, useDetectMobileDevice } from '../lib/browserHooks'
import { getSessionId, getUserId } from '../lib/session.helper'
import '../less/index.less'
import { nanoid } from 'nanoid'

import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CheckIcon from '@mui/icons-material/Check'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import DevManualDebug from '../components/DevManualDebug'
import FastImageMinIcon from '../images/icon.png'
import { useSendVisitStats } from '../lib/statsHooks'

export default function IndexPage (props) {
  const isBrowserSupported = useDetectSupportedBrowser()
  useSendVisitStats()

  return (
    <PageWrapper id="home" {...props}>
      <HeadMeta {...props} />
      {!isBrowserSupported && <BrowserNotSupportedWarningAlert {...props} />}
      <MinificationArea {...props} />
      <WordingContent {...props} />
      {/* <DevManualDebug /> */}
    </PageWrapper>
  )
}

function HeadMeta (props) {
  return (
    <Helmet defer={false} htmlAttributes={{ lang: 'en' }}>
      <title>FastImageMin - Efficiently Compress & Resize JPEG, PNG, WebP Images. Fast & free!</title>
      <meta name="description" content='FastImageMin is an efficient image optimization tool with better compression rate and resizing feature in high speed, without uploading to server. Free & privacy respected.' />
      {/* <meta name="description" content="FastImageMin optimizes JPEG, PNG, and WebP images efficiently with better compression rate and less overhead. You can also resize the image to the appropriate best size." /> */}
      <script src="https://cdn.jsdelivr.net/gh/nicolaspanel/numjs@0.15.1/dist/numjs.min.js"></script>
    </Helmet>
  )
}

function BrowserNotSupportedWarningAlert (props) {
  const isMobileDevice = useDetectMobileDevice()
  const secondLineWording = isMobileDevice ? <Typography>Please consider updating your browser to the latest version, or use desktop browser instead.</Typography> : <Typography>Please consider updating your browser to the latest version.</Typography>
  return (
    <Alert id="browser-not-supported-warning" severity="warning">
      <AlertTitle>Warning</AlertTitle>
      <Typography>Your browser is <strong>not supported</strong></Typography>
      <br />
      {secondLineWording}
    </Alert>
  )
}

function WordingContent (props) {
  return (
    <Container id="wording-content" maxWidth="md">
      <Typography variant="h1" className="title">Efficient Optimization for JPEG, PNG, and WebP Images</Typography>

      <Container maxWidth='sm'>
        <List>
          <ListItem>
            <ListItemIcon><CheckIcon /></ListItemIcon>
            <ListItemText>Better compression rate with less overhead</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><CheckIcon /></ListItemIcon>
            <ListItemText>Optionally resize dimension to a more appropriate size</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><CheckIcon /></ListItemIcon>
            <ListItemText>High quality optimization with fast image processing</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><CheckIcon /></ListItemIcon>
            <ListItemText>Reduce bandwidth usage to very minimum</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><CheckIcon /></ListItemIcon>
            <ListItemText>Privacy is respected, we never store your images </ListItemText>
          </ListItem>
        </List>
      </Container>

    </Container>
  )
}
